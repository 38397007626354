html, body {
  margin: 0;
  background-color: #F4F4F4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: auto;
  position: fixed;
}

.App {
  background-color: var(--background);
  color: var(--text);
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  position: fixed;
}

.light {
  --primary: #0079FF;
  --primary-2: #BFDDFF;
  --secondary: #EBEBEB;
  --red:#EA4335;
  --red-2:#FAD0CC;
  --text: #1F1F1F;
  --text-2: #4A4A4A;
  --text-3: #ABABAB;
  --background: #F4F4F4;
}

h2 {
  font-size: 22px;
  font-weight: 700;
  color:var(--text-2);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  cursor: default;
}

.error-message {
  color: var(--red);
  font-weight: 600;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  line-height: 22px;
  font-size: 16px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}

.card-large {
  background-color: white;
  padding: 22px 32px;
  margin-bottom: 16px;
  border-radius: 4px;
  width: 618px;
  box-shadow: 1px 1px 7px RGBa(0,0,0,0.16);
}

.card-demi {
  background-color: white;
  padding: 22px 32px;
  margin-bottom: 16px;
  border-radius: 4px;
  width: 268px;
  box-shadow: 1px 1px 7px RGBa(0,0,0,0.16);
}

.content {
  margin-top: 22px;
  width: 682px;
  margin-left: auto;
  margin-right: auto;
}

a {
  color:black;
  text-decoration: none;
  cursor: pointer;
}

.flexgrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.board-content {
  color: #727272;
  font-size: 16px;
  font-weight: 500;
}

* {-webkit-font-smoothing: antialiased;}