.avatar {
    width: 42px;
    padding: 0;
    border-radius: 22px;
    background-color: #EBEBEB;
    height: 42px;
    font-size: 18px;
    color: #4A4A4A;
    cursor: pointer;
}

.avatar span {
    text-align: center;
    margin: 0;
    height: 42px;
    display: block;
    width: 42px;
    line-height: 42px;
    font-weight: 800;
}

.avatar-front {
    z-index: 10;
    position: relative;
}