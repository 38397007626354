.button-base {
    background-color: var(--secondary);
    height: 42px;
    border-radius: 4px;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 18px;
    letter-spacing: 0.47px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 18px;
    font-weight: 600;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button-transparent {
    height: 42px;
    font-size: 18px;
    color: var(--text-2);
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 18px;
    font-weight: 600;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button-content-text {
    width: max-content;
}

.button-base span {
    color: var(--text-2);
}

.button-separator-thin {
    width: 8px;
}