.card-large {
    background-color: white;
    padding: 22px 32px;
    border-radius: 4px;
    box-shadow: 1px 1px 7px RGBa(0,0,0,0.16);
}

.card-medium {
    background-color: white;
    padding: 22px 32px;
    border-radius: 4px;
    box-shadow: 1px 1px 7px RGBa(0,0,0,0.16);
    width:268px;
}

.card-padding-medium {
    padding: 50px 100px;
    width: 482px;
}

.card-align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}