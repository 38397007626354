.navbar {
    height: 60px;
    width: 100%;
    background-color: white;
    display: flex;
    box-shadow: 0px 2px 4px RGBa(0,0,0,0.15);
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #8B8B8B;
}

.right, .left {
    width:0px;
}

.right {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.navbar .titre {
    margin-left:auto;
    margin-right:auto;
    font-weight: 900;
}

.navbar h1 {
    font-size: 22px;
    color: #0079FF;
    font-weight: 600 !important;
}

.navbar .link-left {
    text-decoration: none;
    height: 64px;
    align-items: center;
    display: flex;
    position: relative;
    left: 22px;
    top: 0;
}

.navbar .link-left img {
    padding-right: 8px;
}

.link-right {
    text-decoration: none;
    height: 64px;
    align-items: center;
    display: flex;
    position: relative;
    right: 22px;
    top: 0;
}

.back-chevron {
    vertical-align: middle;
}