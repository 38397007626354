.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: white;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
  justify-content: center;
  align-items: center;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 4px;
    border-color: rgba(0, 121, 255, 0.05);
    border-top-color: #0079FF;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.overlay__logo {
    width: 150px;
    margin-bottom: 200px;
}

.overlay__container {
  position: relative;
  height: 300px;
}