.container-fit-1 {
    width: 682px;
    margin-left: auto;
    margin-right: auto;
}

.container-fit-2 {
    width: 682px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}