.card-large {
    background-color: white;
    padding: 22px 32px;
    border-radius: 4px;
    box-shadow: 1px 1px 7px RGBa(0,0,0,0.16);
}

.card-title {
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 12px 0;
    margin: 0;
}

.card-description {
    padding: 0;
    margin: 0;
    letter-spacing: 0.47px;
    line-height: 22px;
    color: #515151;
}