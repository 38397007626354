.dropdown-container {
    display: block;
    right: 0;
    top: 0;
    background-color: red;
    min-width: 250px;
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}

.dropdown-content {
    display: block;
    position: absolute;
    right: 0;
    top: 10px;
    background-color: #f9f9f9;
    min-width: 280px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
    right: -6px;
    top: 6px;
    border-radius: 6px;
} 

.dropdown-content .dropdown-header {
    font-weight: 700;
    height: 28px;
    align-items: center;
    display: flex;
}
  
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* .dropdown-content .dropdown-header:hover {
    background-color: rgba(0,0,0,0);
    color: var(--text);
} */

/* .dropdown-content a:hover {
    background-color: var(--primary-2);
    color: var(--primary);
} */

.dropdown-content-item {
    height: 42px;
}

.dropdown-content-item:hover {
    background-color: var(--primary-2);
    color: var(--primary);
}


.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .dropbtn {background-color: #3e8e41;} 